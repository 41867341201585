
<template>
  <!-- Footer Section -->
  <footer>
    <div class="container-fluid p-0">
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <div class="f-item">
              <figure class="logo">
                <img src="../assets/logos/dental-point-logo-1.png" alt="logo" />
              </figure>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="f-item">
              <h2>{{ $t("Contact") }}</h2>
              <ul>
                <li>
                  <a href="tel:+905333530799" :title="$t('Telephone')"
                    >+90 533 353 07 99</a
                  >
                </li>
                <li>
                  <a
                    href="mailto:info@dentalpointturkey.com"
                    :title="$t('Email')"
                    >info@dentalpointturkey.com</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="f-item">
              <h2>Quick Links</h2>
              <ul>
                <li>
                  <router-link to="/about-us" tag="a" :title="$t('AboutUs')">{{
                    $t("AboutUs")
                  }}</router-link>
                </li>
                <li>
                  <router-link to="/contact" tag="a" :title="$t('Contact')">{{
                    $t("Contact")
                  }}</router-link>
                </li>
                <li>
                  <router-link
                    to="/privacy-policy"
                    tag="a"
                    :title="$t('PrivacyPolicy')"
                    >{{ $t("PrivacyPolicy") }}</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/terms-conditions"
                    tag="a"
                    :title="$t('TermsConditions')"
                    >{{ $t("TermsConditions") }}</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="f-item">
              <h2>{{ $t("Address") }}</h2>
              <p class="mt-3">
                {{ $t("AddressDesc") }}
              </p>
              <div class="footer-icons">
                <a href="#" title="facebook">
                  <img src="../assets/icons/facebook-b.svg" alt="facebook" />
                </a>
                <a href="#" title="twitter">
                  <img src="../assets/icons/twitter-b.svg" alt="twitter" />
                </a>
                <a href="#" title="instagram">
                  <img src="../assets/icons/instagram-b.svg" alt="instagram" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="text">
          {{ $t("FooterCopyrightDesc1") }}
        </div>
        <div class="text-center mt-3">
          &copy; {{ $t("FooterCopyrightDesc2") }}
        </div>
      </div>
    </div>
  </footer>
  <!-- Footer Section -->
</template>
<script>
export default {
  methods: {
    scrollToSection(sectionId) {
      let hostName = window.location.pathname;
      if (hostName == "/our-services") {
        window.location = `/#${sectionId}`;
      } else {
        // İlgili bölümü bulmak için ID'ye göre seçim yapalım
        const section = document.getElementById(sectionId);

        if (section) {
          // Bölümü ekranın üstüne getirelim
          section.scrollIntoView({ behavior: "smooth" });
        }
      }
    },
  },
};
</script>
