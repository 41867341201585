<template>
  <div>
    <h2>24/7&nbsp;{{ $t("ContactInformation") }}</h2>
    <div class="col">
      <a href="tel:+905333530799" title="+90 533 353 07 99"
        >+90 533 353 07 99
      </a>
      &nbsp;{{ $t("DirectPhoneNumber") }}
    </div>
    <div class="col">
      <a href="tel:+905333530799" title="+90 533 353 07 99"
        >+90 533 353 07 99</a
      >
      &nbsp;{{ $t("WhatsappHelpDesk") }}
    </div>
    <div class="col">
      <a
        href="mailto:info@dentalpointturkey.com"
        title="info@dentalpointturkey.com"
      >
        info@dentalpointturkey.com
      </a>
      &nbsp;{{ $t("EMailAddress") }}
    </div>
    <div class="col">
      {{ $t("ClickToAccess")
      }}<router-link to="/contact" tag="a" :title="$t('LocationInformation')"
        >&nbsp;{{ $t("LocationInformation") }}</router-link
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>