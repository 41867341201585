<template>
  <section>
    <pageWrapper />
    <div class="container contracts">
      <h2>
        {{ $t("PrivacyPolicy") }}
      </h2>
      <p class="mt-2 mb-0">
        <strong>
          {{ $t("Introduction") }}
        </strong>
      </p>
      <p>
        {{ $t("IntroductionDesc") }}
      </p>
      <p class="mt-2 mb-0">
        <strong>
          {{ $t("UsingOurServices") }}
        </strong>
      </p>
      <p>
        {{ $t("UsingOurServicesDesc") }}
      </p>
      <p class="mt-2 mb-0">
        <strong>
          {{ $t("PrivacyAndCopyrightProtection") }}
        </strong>
      </p>
      <p>
        {{ $t("PrivacyAndCopyrightProtectionDesc") }}
      </p>
      <p class="mt-2 mb-0">
        <strong>
          {{ $t("YourContentInOurServices") }}
        </strong>
      </p>
      <p>
        {{ $t("YourContentInOurServicesDesc") }}
      </p>
    </div>
  </section>
</template>

<script>
import pageWrapper from "@/components/Page-Wrapper.vue";

export default {
  components: { pageWrapper },
};
</script>

<style>
</style>