<template>
  <main class="main">
    <!-- main section -->
    <section class="main-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 main-left">
            <div class="left">
              <h2>
                {{ $t("MainContentTitle") }}
              </h2>
              <p>{{ $t("MainContentDesc") }}</p>
              <div class="main-btn">
                <router-link to="/contact" tag="a" title="Make An Appointment"
                  >{{ $t("MakeAnAppointment") }}
                  <img
                    class="calendar"
                    src="../assets/icons/calendar-w.svg"
                    alt="calendar"
                /></router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="right">
              <!-- slider -->
              <VueperSlides
                arrows-outside
                :bullets="false"
                :slideMultiple="true"
                :gap="5"
                :lazy="true"
                autoplay
                :slide-ratio="1 / 4"
                class="no-shadow vueper-slide"
                :visible-slides="1"
                :dragging-distance="70"
                :breakpoints="{
                  800: {
                    visibleSlides: 1,
                    slideMultiple: 1,
                  },
                }"
              >
                <VueperSlide
                  class="slide-item"
                  v-for="(slide, i) in homeSlides"
                  :key="i"
                  :image="slide.image"
                >
                </VueperSlide>
              </VueperSlides>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end main section -->

    <!-- spacer
    <div class="spacer-r">
      <div class="spacer-content">
        <span>Contact</span>
      </div>
    </div> -->

    <!-- home services start -->
    <div class="container home-services">
      <div class="text-center">
        <h2>{{ $t("DentalPointServices") }}</h2>
        <p>
          {{ $t("DentalPointServicesDesc") }}
        </p>
      </div>
      <div class="services-icons">
        <div class="box">
          <img src="../assets/icons/dental-bridge.svg" alt="services" />
          <span> {{ $t("DentalBridgeWork") }} </span>
        </div>
        <div class="box">
          <img src="../assets/icons/dental-laser.svg" alt="services" />
          <span> {{ $t("LaserTeethWhitening") }}</span>
        </div>
        <div class="box">
          <img src="../assets/icons/dental-canal.svg" alt="services" />
          <span> {{ $t("RootCanalTreatments") }} </span>
        </div>
        <div class="box">
          <img src="../assets/icons/dental-crowns.svg" alt="services" />
          <span> {{ $t("DentalCrowns") }} </span>
        </div>
        <div class="box">
          <img src="../assets/icons/dental-implants.svg" alt="services" />
          <span> {{ $t("DentalImplants") }} </span>
        </div>
      </div>
      <div class="text-center services-btn">
        <router-link to="/treatments" :title="$t('AllServices')" tag="a">{{
          $t("AllServices")
        }}</router-link>
      </div>
    </div>
    <!-- home services end -->

    <!-- sticky wrapper -->
    <div class="s-wrapper"></div>

    <!-- Testimonial -->
    <Testimonial />
    <!-- home contact start -->
    <section class="contact-form mt-1">
      <contactForm />
    </section>
    <!-- home contact end -->
  </main>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import contactForm from "../components/Contact-Form.vue";
import Testimonial from "../components/Testimonial-Comp.vue";
export default {
  components: {
    VueperSlides,
    VueperSlide,
    contactForm,
    Testimonial,
  },
  data() {
    return {
      homeSlides: [
        {
          image: require("@/assets/home-slider/home-slider-1.jpg"),
        },
        {
          image: require("@/assets/home-slider/home-slider-2.jpg"),
        },
        {
          image: require("@/assets/home-slider/home-slider-3.jpg"),
        },
      ],
    };
  },
  created() {
    if (window.innerWidth < 997) {
      this.roomsVisibleSlides = 1;
    } else {
      this.roomsVisibleSlides = 3;
    }
  },
};
</script>