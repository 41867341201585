<template>
  <section>
    <pageWrapper />
    <div class="container our-clinic">
      <h2>{{ $t("AboutOurClinic") }}</h2>
      <p></p>
      <p>
        {{ $t("AboutOurClinicDesc") }}
      </p>
      <ul>
        <li>{{ $t("OurClinicDesc1") }}</li>
        <li>{{ $t("OurClinicDesc2") }}</li>
        <li>{{ $t("OurClinicDesc3") }}</li>
        <li>{{ $t("OurClinicDesc4") }}</li>
        <li>{{ $t("OurClinicDesc5") }}</li>
        <li>{{ $t("OurClinicDesc6") }}</li>
        <li>{{ $t("OurClinicDesc7") }}</li>
        <li>{{ $t("OurClinicDesc8") }}</li>
      </ul>
      <div class="row">
        <div class="col-lg-12">
          <div class="sw"></div>
          <h2 class="mt-4">{{ $t("WhatSetsUsApart") }}</h2>
          <ul>
            <li>
              {{ $t("WhatSetsUsApart1") }}
            </li>
            <li>
              {{ $t("WhatSetsUsApart2") }}
            </li>
            <li>
              {{ $t("WhatSetsUsApart3") }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import pageWrapper from "../components/Page-Wrapper.vue";
export default {
  data() {
    return {};
  },
  components: {
    pageWrapper,
  },
};
</script>

<style>
</style>