<template>
  <section class="t-detail">
    <pageWrapper />
    <div class="container my-5">
      <div class="row">
        <div class="col-lg-8">
          <div class="col">
            <h1>{{ $t("DentalDiseases") }}</h1>
            <p>
              {{ $t("DentalDiseasesDesc1") }}
            </p>
            <p class="mt-2 mb-0">
              <strong>
                {{ $t("ToothDecay") }}
              </strong>
            </p>
            <p>
              {{ $t("ToothDecayDesc") }}
            </p>
            <p class="mt-2 mb-0">
              <strong>
                {{ $t("GumDisease") }}
              </strong>
            </p>
            <p>
              {{ $t("GumDiseaseDesc") }}
            </p>
            <p class="mt-2 mb-0">
              <strong>
                {{ $t("OralCancer") }}
              </strong>
            </p>
            <p>
              {{ $t("OralCancerDesc") }}
            </p>
            <p class="mt-2 mb-0">
              <strong>
                {{ $t("DentalAbscess") }}
              </strong>
            </p>
            <p>
              {{ $t("DentalAbscessDesc") }}
            </p>
            <p class="mt-2 mb-0">
              <strong>
                {{ $t("ToothSensitivity") }}
              </strong>
            </p>
            <p>
              {{ $t("ToothSensitivityDesc") }}
            </p>
            <p class="mt-2 mb-0">
              <strong>
                {{ $t("ToothGrinding") }}
              </strong>
            </p>
            <p>
              {{ $t("ToothGrindingDesc") }}
            </p>
            <p class="mt-2 mb-0">
              <strong>
                {{ $t("ToothErosion") }}
              </strong>
            </p>
            <p>
              {{ $t("ToothErosionDesc") }}
            </p>
            <p class="mt-2 mb-0">
              <strong>
                {{ $t("Malocclusion") }}
              </strong>
            </p>
            <p>
              {{ $t("MalocclusionDesc") }}
            </p>
            <p>
              {{ $t("DentalDiseasesDesc2") }}
            </p>
          </div>
          <div class="col">
            <router-link
              class="contact-btn"
              tag="a"
              to="/contact"
              title="Contact"
              >{{ $t("MakeAnAppointment") }}
              <img
                class="calendar"
                src="@/assets/icons/calendar-w.svg"
                alt="calendar"
                loading="lazy"
            /></router-link>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="container my-5 contact-side">
            <div class="row">
              <div class="col">
                <figure>
                  <img
                    class="img-thumbnail"
                    src="@/assets/treatments/Dental-Diseases.jpeg"
                    alt="Dental Diseases"
                    loading="lazy"
                  />
                </figure>
                <div class="col">
                  <ContactSide />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import pageWrapper from "@/components/Page-Wrapper.vue";
import ContactSide from "@/components/Treatment-Contact-Side.vue";
export default {
  data() {
    return {};
  },
  components: {
    ContactSide,
    pageWrapper,
  },
};
</script>

<style>
</style>