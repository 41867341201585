<template>
  <section class="contact-page">
    <pageWrapper />

    <div class="container contact-boxes">
      <div class="row">
        <div class="col-lg-4">
          <div class="contact-box">
            <h2>
              {{ $t("Address") }}
              <!-- <img src="../assets/icons/location-pin.svg" alt="location" /> -->
            </h2>
            <p>
              {{ $t("AddressDesc") }}
            </p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="contact-box">
            <h2>
              {{ $t("Email") }}
              <!-- <img src="../assets/icons/send-b.svg" alt="email" /> -->
            </h2>
            <p>
              <a href="mailto:info@dentalpointturkey.com" :title="$t('Email')"
                >info@dentalpointturkey.com</a
              >
            </p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="contact-box">
            <h2>
              {{ $t("Telephone") }}
              <!-- <img src="../assets/icons/phone.svg" alt="phone" /> -->
            </h2>
            <p>
              <a href="tel:+905333530799" :title="$t('Telephone')">
                +90 533 353 07 99
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <contactForm />
  </section>
</template>
<script>
import pageWrapper from "../components/Page-Wrapper.vue";
import contactForm from "../components/Contact-Form.vue";
export default {
  data() {
    return {};
  },
  components: {
    pageWrapper,
    contactForm,
  },
};
</script>

<style>
</style>