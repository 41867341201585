<template>
  <div class="container contact-form">
    <div class="row">
      <div class="col-lg-6 contact-maps">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3190.7554496150606!2d30.675718082118184!3d36.89619819067064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c3850b6153b0d1%3A0x5a0ab8075615ed64!2sDental%20Point%20Turkey!5e0!3m2!1sen!2str!4v1697366203495!5m2!1sen!2str"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          title="Location"
        ></iframe>
      </div>
      <div class="col-lg-6 form">
        <div class="pe-lg-0">
          <div class="mx-0" style="max-width: 600px">
            <p>
              {{ $t("ContactFormDesc") }}
            </p>
          </div>
          <form id="myForm" ref="contactForm">
            <div class="row g-3 text-center">
              <div class="col-12 col-sm-6 text-left">
                <label for="name"> {{ $t("Name") }}* </label>
                <input
                  id="name"
                  type="text"
                  class="form-control"
                  name="name"
                  :placeholder="$t('Name')"
                  v-model="name"
                  maxlength="40"
                  style="height: 55px"
                />
              </div>
              <div class="col-12 col-sm-6">
                <label for="phone">{{ $t("Telephone") }}*</label>
                <input
                  id="phone"
                  type="text"
                  class="form-control"
                  :placeholder="$t('Telephone')"
                  name="phone"
                  v-model="phone"
                  maxlength="20"
                  style="height: 55px"
                />
              </div>
              <div class="col-lg-12 col-sm-6">
                <label for="email">{{ $t("Email") }}*</label>
                <input
                  id="email"
                  type="email"
                  class="form-control"
                  :placeholder="$t('Email')"
                  name="email"
                  v-model="mail"
                  maxlength="50"
                  style="height: 55px"
                />
              </div>
              <div class="col-12">
                <label for="message">{{ $t("Message") }}*</label>
                <textarea
                  id="message"
                  class="form-control"
                  :placeholder="$t('TypeYourMessage')"
                  rows="5"
                  name="message"
                  v-model="message"
                  maxlength="250"
                ></textarea>
              </div>
              <input type="hidden" name="website" value="Dental Point" />
              <div class="col-12 text-center d-flex justify-content-center">
                <div class="text-center my-1"></div>
              </div>
            </div>
          </form>
          <button
            class="btn w-100 text-left"
            id="sendButton"
            type="submit"
            @click="sendMail"
          >
            {{ $t("SubmitForm") }} &nbsp;
            <img class="send-w" src="../assets/icons/send-w.svg" alt="send" />
            <img class="send-b" src="../assets/icons/send-b.svg" alt="send" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
export default {
  data() {
    return {
      mail: "",
      phone: "",
      name: "",
      message: "",
    };
  },

  methods: {
    async sendMail() {
      let name = this.name;
      let phone = this.phone;
      let mail = this.mail;
      let message = this.message;
      if (!name) {
        alert("Name cannot be empty!");
        return;
      }
      if (!phone) {
        alert("Telephone cannot be empty!");
        return;
      }
      if (!mail) {
        alert("E Mail cannot be empty!");
        return;
      }
      if (!message) {
        alert("Message cannot be empty!");
        return;
      }
      try {
        emailjs.sendForm(
          "service_dg1je0q",
          "template_i0mgz7h",
          this.$refs.contactForm,
          "user_xPWQO0jdM5pxby29iSKYJ"
        );
        this.showInfo(this.$t("MailSent"));
        // Reset form field
        this.mail = "";
        this.phone = "";
        this.name = "";
        this.message = "";
      } catch (error) {
        console.log({ error });
      }
    },
    showInfo(message) {
      event.preventDefault();
      const a = document.getElementById("sendButton");
      a.innerText = message;
      setTimeout(() => {
        const a = document.getElementById("sendButton");
        a.innerText = this.$t("SubmitForm");
      }, 2000);
    },
  },
};
</script>

<style>
</style>