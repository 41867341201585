<template>
  <div class="page-wrapper">
    <span
      ><router-link to="/" :title="$t('Home')">{{
        $t("Home")
      }}</router-link></span
    >
    <span>&nbsp;/&nbsp;</span>
    <span ref="pageName"></span>
  </div>
</template>

<script>
export default {
  mounted() {
    let url = window.location.pathname;
    if (url) {
      if (url == "/about-us") {
        this.$refs.pageName.innerHTML = this.$t("AboutUs");
        return;
      }
      if (url == "/contact") {
        this.$refs.pageName.innerHTML = this.$t("Contact");
        return;
      }
      if (url == "/our-clinic") {
        this.$refs.pageName.innerHTML = this.$t("OurClinic");
        return;
      }
      if (url == "/gallery") {
        this.$refs.pageName.innerHTML = this.$t("Gallery");
        return;
      }
      if (url == "/health-tourism") {
        this.$refs.pageName.innerHTML = this.$t("TravelForHealth");
        return;
      }
      if (url == "/treatments") {
        this.$refs.pageName.innerHTML = this.$t("Treatments");
        return;
      }
      if (url == "/privacy-policy") {
        this.$refs.pageName.innerHTML = this.$t("PrivacyPolicy");
        return;
      }
      if (url == "/terms-conditions") {
        this.$refs.pageName.innerHTML = this.$t("TermsAndConditions");
        return;
      } else {
        this.$refs.pageName.innerHTML = "Dental Point";
        return;
      }
    }
  },
};
</script>

<style scoped>
.page-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/home-slider/slider-1.jpg");
  background-position: center;
  background-size: cover;
  height: 250px;
  position: relative;
  color: #fff;
  z-index: 9;
  padding: 0 5px;
}
.page-wrapper::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: -1;
}
.page-wrapper span {
  font-size: 1.4em;
}
.page-wrapper a {
  color: #fff;
  text-decoration: none;
}
@media (max-width: 996px) {
  .page-wrapper span {
    font-size: 1.2em;
  }
}
</style>