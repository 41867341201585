<template>
  <header>
    <div class="top-header" v-if="show_web_header == true">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 p-0">
            <figure class="logo">
              <router-link to="/" tag="a" title="Home">
                <img
                  src="../assets/logos/dental-point-logo-1.png"
                  alt="Logo"
                  title="Dental Point"
              /></router-link>
            </figure>
          </div>
          <div class="col-lg-6">
            <div class="top-header-r">
              <div class="top-header-r-btn">
                <router-link to="/contact" tag="a" title="Contact">{{
                  $t("GetAQuote")
                }}</router-link>
              </div>
              <div class="top-header-r-icons">
                <figure>
                  <img
                    src="../assets/icons/en.png"
                    alt="English"
                    title="English"
                    @click="ChangeLanguage('en')"
                  />
                </figure>
                <figure>
                  <img
                    src="../assets/icons/tr.png"
                    alt="Türkçe"
                    title="Türkçe"
                    @click="ChangeLanguage('tr')"
                  />
                </figure>
                <figure>
                  <img
                    src="../assets/icons/ru.png"
                    alt="Russian"
                    title="Russian"
                    @click="ChangeLanguage('ru')"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Navbar Start -->
    <nav
      v-if="show_web_header == true"
      class="web-navbar navbar navbar-expand-lg"
    >
      <div class="container" id="navbarCollapse">
        <div class="navbar-nav">
          <ul>
            <li class="nav-item">
              <router-link to="/" class="nav-link active" :title="$t('Home')">{{
                $t("Home")
              }}</router-link>
            </li>
            <li class="nav-item">
              <router-link
                to="/health-tourism"
                class="nav-link"
                :title="$t('TravelForHealth')"
                >{{ $t("TravelForHealth") }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                to="/treatments"
                class="nav-link"
                :title="$t('Treatments')"
                >{{ $t("Treatments") }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                to="/gallery"
                class="nav-link"
                :title="$t('Gallery')"
                >{{ $t("Gallery") }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                to="/our-clinic"
                class="nav-link"
                :title="$t('OurClinic')"
                >{{ $t("OurClinic") }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                to="/about-us"
                class="nav-link"
                :title="$t('AboutUs')"
                >{{ $t("AboutUs") }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                to="/contact"
                class="nav-link"
                :title="$t('Contact')"
                >{{ $t("Contact") }}</router-link
              >
            </li>
          </ul>
        </div>
        <div class="nav-icons">
          <a href="#" title="facebook">
            <img src="../assets/icons/facebook-b.svg" alt="facebook" />
          </a>
          <a href="#" title="instagram">
            <img src="../assets/icons/instagram-b.svg" alt="instagram" />
          </a>
          <a href="#" title="twitter">
            <img src="../assets/icons/twitter-b.svg" alt="twitter" />
          </a>
        </div>
      </div>
    </nav>

    <!-- mobile navbar section -->
    <nav v-if="show_mobile_header == true" id="mobile-navbar">
      <div class="container-fluid h-100">
        <div
          class="row h-100 text-center d-flex justify-content-center align-items-center"
        >
          <div
            class="col d-flex justify-content-center align-items-center mobile-lang"
          >
            <div>
              <ul>
                <li>
                  <b-dropdown :text="this.$i18n.locale" left>
                    <b-dropdown-item
                      ><span
                        class="dropdown-item"
                        title="Türkçe"
                        @click="ChangeLanguage('tr')"
                        >TR &nbsp;
                        <img
                          src="../assets/icons/tr.png"
                          alt="Türkçe"
                          title="Türkçe"
                          @click="ChangeLanguage('tr')" /></span
                    ></b-dropdown-item>
                    <b-dropdown-item
                      ><span
                        class="dropdown-item"
                        title="English"
                        @click="ChangeLanguage('en')"
                        >EN &nbsp;
                        <img
                          src="../assets/icons/en.png"
                          alt="Türkçe"
                          title="Türkçe"
                          @click="ChangeLanguage('en')" /></span
                    ></b-dropdown-item>
                    <b-dropdown-item
                      ><span
                        class="dropdown-item"
                        title="Russian"
                        @click="ChangeLanguage('ru')"
                        >RU &nbsp;
                        <img
                          src="../assets/icons/ru.png"
                          alt="Türkçe"
                          title="Türkçe"
                          @click="ChangeLanguage('ru')" /></span
                    ></b-dropdown-item>
                  </b-dropdown>
                </li>
              </ul>
            </div>
          </div>
          <div class="col">
            <router-link to="/" :title="$t('Home')">
              <img
                class="mobile-logo"
                src="../assets/logos/dental-point-logo-1.png"
                alt="logo"
              />
            </router-link>
          </div>
          <div class="col">
            <span class="mobile-toggler" @click="TOGGLE()">⇆</span>
            <div class="col mobile-nav-items">
              <ul>
                <li @click="TOGGLE()">
                  <router-link
                    to="/health-tourism"
                    class="nav-item nav-link"
                    :title="$t('TravelForHealth')"
                    >{{ $t("TravelForHealth") }}</router-link
                  >
                </li>
                <li @click="TOGGLE()">
                  <router-link
                    to="/treatments"
                    class="nav-item nav-link"
                    :title="$t('Treatments')"
                    >{{ $t("Treatments") }}</router-link
                  >
                </li>
                <li @click="TOGGLE()">
                  <router-link
                    to="/gallery"
                    class="nav-item nav-link"
                    :title="$t('Gallery')"
                    >{{ $t("Gallery") }}</router-link
                  >
                </li>
                <li @click="TOGGLE()">
                  <router-link
                    to="/our-clinic"
                    class="nav-item nav-link"
                    :title="$t('OurClinic')"
                    >{{ $t("OurClinic") }}</router-link
                  >
                </li>
                <li @click="TOGGLE()">
                  <router-link
                    to="/about-us"
                    class="nav-item nav-link"
                    :title="$t('AboutUs')"
                    >{{ $t("AboutUs") }}</router-link
                  >
                </li>
                <li @click="TOGGLE()">
                  <router-link
                    to="/contact"
                    class="nav-item nav-link"
                    :title="$t('Contact')"
                    >{{ $t("Contact") }}</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <!-- Navbar End -->
  </header>
</template>
<script>
export default {
  data() {
    return {
      show_web_header: false,
      show_mobile_header: false,
    };
  },
  created() {
    // eğer ekran genişliği değiştirilirse bu event sayesinde fonksiyonu yeniden tetikliyoruz
    window.addEventListener("resize", () => {
      this.FindScreenSize();
    });
    //ilk açılışta her zaman bu kontrol gerçekleşecek
    this.FindScreenSize();
  },
  methods: {
    FindScreenSize() {
      this.show_mobile_header = window.innerWidth < 996; // window.innerWidth < 996 bu kod boolean döndürür.
      this.show_web_header = !this.show_mobile_header; // show_web_header değişkeni sürekli diğerine bağlı olarak zıt şekilde güncellenir.
    },
    ChangeLanguage(lang) {
      if (lang == "tr") {
        this.$i18n.locale = "tr";
        // Yeni dil değerini yerel depolamada saklayın
        localStorage.setItem("lang", this.$i18n.locale);
        return;
      }
      if (lang == "ru") {
        this.$i18n.locale = "ru";
        // Yeni dil değerini yerel depolamada saklayın
        localStorage.setItem("lang", this.$i18n.locale);
        return;
      }
      this.$i18n.locale = this.$i18n.locale = "en";
      // Yeni dil değerini yerel depolamada saklayın
      localStorage.setItem("lang", this.$i18n.locale);
    },

    TOGGLE() {
      // mobile toggle controller
      let toggle = document.querySelector(".mobile-nav-items");
      if (toggle.classList.contains("show")) {
        toggle.style.display = "none";
        toggle.classList.remove("show");
      } else {
        toggle.style.display = "block";
        toggle.classList.add("show");
      }
    },
  },
};
</script>