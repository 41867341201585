<template>
  <section>
    <pageWrapper />
    <div class="container treatments">
      <div class="top">
        <h2>{{ $t("Treatments") }}</h2>
        <p>
          {{ $t("TreatmentsDesc") }}
        </p>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="top-item">
            <img
              src="@/assets/icons/dental-canal.svg"
              alt="treatment"
              loading="lazy"
            />
            <h2>{{ $t("FreePreInterview") }}</h2>
            <p>
              {{ $t("FreePreInterviewDesc") }}
            </p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="top-item">
            <img
              src="@/assets/icons/dental-implants.svg"
              alt="treatment"
              loading="lazy"
            />
            <h2>{{ $t("EvaluationAndPlanning") }}</h2>
            <p>
              {{ $t("EvaluationAndPlanningDesc") }}
            </p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="top-item">
            <img
              src="@/assets/icons/dental-laser.svg"
              alt="treatment"
              loading="lazy"
            />
            <h2>{{ $t("PreAndPostTreatmentCare") }}</h2>
            <p>
              {{ $t("PreAndPostTreatmentCareDesc") }}
            </p>
          </div>
        </div>
      </div>
      <div class="boxes">
        <div class="row">
          <div class="col-lg-4">
            <figure>
              <img
                src="../assets/treatments/Smile-Design.jpg"
                alt="Smile Design"
                loading="lazy"
              />
            </figure>
            <div class="item">
              <router-link
                to="/smile-design"
                tag="a"
                :title="$t('SmileDesign')"
              >
                <h2>{{ $t("SmileDesign") }}</h2>
              </router-link>
              <p>{{ $t("SmileDesignDesc").slice(0, 100) }}...</p>
              <router-link tag="a" to="/smile-design" :title="$t('ReadMore')">{{
                $t("ReadMore")
              }}</router-link>
            </div>
          </div>
          <div class="col-lg-4">
            <figure>
              <img
                src="../assets/treatments/Teeth-Whitening.jpg"
                alt="Teeth Whitening"
                loading="lazy"
              />
            </figure>
            <div class="item">
              <router-link
                to="/teeth-whitening"
                tag="a"
                :title="$t('TeethWhitening')"
              >
                <h2>{{ $t("TeethWhitening") }}</h2>
              </router-link>
              <p>{{ $t("TeethWhiteningDesc1").slice(0, 100) }}...</p>
              <router-link
                tag="a"
                to="/teeth-whitening"
                :title="$t('ReadMore')"
                >{{ $t("ReadMore") }}</router-link
              >
            </div>
          </div>
          <div class="col-lg-4">
            <figure>
              <img
                src="../assets/treatments/Implant.jpg"
                alt="Implant"
                loading="lazy"
              />
            </figure>
            <div class="item">
              <router-link to="/implant" tag="a" :title="$t('Implant')">
                <h2>{{ $t("Implant") }}</h2>
              </router-link>
              <p>{{ $t("ImplantDesc1").slice(0, 100) }}...</p>
              <router-link tag="a" to="/implant" :title="$t('ReadMore')">{{
                $t("ReadMore")
              }}</router-link>
            </div>
          </div>
          <div class="col-lg-4">
            <figure>
              <img
                src="../assets/treatments/Zirconium-Crowns.jpg"
                alt="Zirconium Crowns"
                loading="lazy"
              />
            </figure>
            <div class="item">
              <router-link
                to="/zirconium-crowns"
                tag="a"
                :title="$t('ZirconiumCrowns')"
              >
                <h2>{{ $t("ZirconiumCrowns") }}</h2>
              </router-link>
              <p>{{ $t("ZirconiumCrownsDesc1").slice(0, 100) }}...</p>
              <router-link
                tag="a"
                to="/zirconium-crowns"
                :title="$t('ReadMore')"
                >{{ $t("ReadMore") }}</router-link
              >
            </div>
          </div>
          <div class="col-lg-4">
            <figure>
              <img
                src="../assets/treatments/Impacted-Wisdom-Teeth.jpg"
                alt="Impacted Wisdom"
                loading="lazy"
              />
            </figure>
            <div class="item">
              <router-link
                to="/impacted-wisdom-teeth"
                tag="a"
                :title="$t('ImpactedWisdomTeeth')"
              >
                <h2>{{ $t("ImpactedWisdomTeeth") }}</h2>
              </router-link>
              <p>{{ $t("ImpactedWisdomTeethDesc1").slice(0, 100) }}...</p>
              <router-link
                tag="a"
                to="/impacted-wisdom-teeth"
                :title="$t('ReadMore')"
                >{{ $t("ReadMore") }}</router-link
              >
            </div>
          </div>
          <div class="col-lg-4">
            <figure>
              <img
                src="../assets/treatments/Dental-Diseases.jpeg"
                alt="Dental Diseases"
                loading="lazy"
              />
            </figure>
            <div class="item">
              <router-link
                to="/dental-diseases"
                tag="a"
                :title="$t('DentalDiseases')"
              >
                <h2>{{ $t("DentalDiseases") }}</h2>
              </router-link>
              <p>{{ $t("DentalDiseasesDesc1").slice(0, 100) }}...</p>
              <router-link
                tag="a"
                to="/dental-diseases"
                :title="$t('ReadMore')"
                >{{ $t("ReadMore") }}</router-link
              >
            </div>
          </div>
          <div class="col-lg-4">
            <figure>
              <img
                src="../assets/treatments/Orthodontics.jpg"
                alt="Orthodontics"
                loading="lazy"
              />
            </figure>
            <div class="item">
              <router-link
                to="/orthodontics"
                tag="a"
                :title="$t('Orthodontics')"
              >
                <h2>{{ $t("Orthodontics") }}</h2>
              </router-link>
              <p>{{ $t("OrthodonticsDesc1").slice(0, 100) }}...</p>
              <router-link tag="a" to="/orthodontics" :title="$t('ReadMore')">{{
                $t("ReadMore")
              }}</router-link>
            </div>
          </div>
          <div class="col-lg-4">
            <figure>
              <img
                src="../assets/treatments/Children-Dentistry.png"
                alt="Children Dentistry"
                loading="lazy"
              />
            </figure>
            <div class="item">
              <router-link
                to="/children-dentistry"
                tag="a"
                :title="$t('ChildrenDentistry')"
              >
                <h2>{{ $t("ChildrenDentistry") }}</h2>
              </router-link>
              <p>{{ $t("ChildrenDentistryDesc1").slice(0, 100) }}...</p>
              <router-link
                tag="a"
                to="/children-dentistry"
                :title="$t('ReadMore')"
                >{{ $t("ReadMore") }}</router-link
              >
            </div>
          </div>
          <div class="col-lg-4">
            <figure>
              <img
                src="../assets/treatments/Cariosity-Treatment.jpg"
                alt="Cariosity Treatment"
                loading="lazy"
              />
            </figure>
            <div class="item">
              <router-link
                to="/cariosity-treatment"
                tag="a"
                :title="$t('CariosityTreatment')"
              >
                <h2>{{ $t("CariosityTreatment") }}</h2>
              </router-link>
              <p>{{ $t("CariosityTreatmentDesc1").slice(0, 100) }}...</p>
              <router-link
                tag="a"
                to="/cariosity-treatment"
                :title="$t('ReadMore')"
                >{{ $t("ReadMore") }}</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import pageWrapper from "../components/Page-Wrapper.vue";
export default {
  data() {
    return {};
  },
  components: {
    pageWrapper,
  },
  created() {},
};
</script>

<style>
</style>