<template>
  <section class="t-detail">
    <pageWrapper />
    <div class="container my-5">
      <div class="row">
        <div class="col-lg-8">
          <div class="col">
            <h1>{{ $t("CariosityTreatment") }}</h1>
            <p>
              {{ $t("CariosityTreatmentDesc1") }}
            </p>
            <p>
              {{ $t("CariosityTreatmentDesc2") }}
            </p>
            <p class="mt-2 mb-0">
              <strong>
                {{ $t("CognitiveBehavioralTherapy") }}
              </strong>
            </p>
            <p>
              {{ $t("CognitiveBehavioralTherapyDesc") }}
            </p>
            <p class="mt-2 mb-0">
              <strong>
                {{ $t("ExposureAndResponsePrevention") }}
              </strong>
            </p>
            <p>
              {{ $t("ExposureAndResponsePreventionDesc") }}
            </p>
            <p class="mt-2 mb-0">
              <strong>
                {{ $t("MindfulnessBasedTechniques") }}
              </strong>
            </p>
            <p>
              {{ $t("MindfulnessBasedTechniquesDesc") }}
            </p>
            <p class="mt-2 mb-0">
              <strong>
                {{ $t("EducationAndInformationManagement") }}
              </strong>
            </p>
            <p>
              {{ $t("EducationAndInformationManagementDesc") }}
            </p>
            <p class="mt-2 mb-0">
              <strong>
                {{ $t("Medication") }}
              </strong>
            </p>
            <p>
              {{ $t("MedicationDesc") }}
            </p>
            <p>
              {{ $t("CariosityTreatmentDesc3") }}
            </p>
          </div>
          <div class="col">
            <router-link
              class="contact-btn"
              tag="a"
              to="/contact"
              title="Contact"
              >{{ $t("MakeAnAppointment") }}
              <img
                class="calendar"
                src="@/assets/icons/calendar-w.svg"
                alt="calendar"
                loading="lazy"
            /></router-link>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="container my-5 contact-side">
            <div class="row">
              <div class="col">
                <figure>
                  <img
                    class="img-thumbnail"
                    src="@/assets/treatments/Cariosity-Treatment.jpg"
                    alt="Cariosity Treatment"
                    loading="lazy"
                  />
                </figure>
                <div class="col">
                  <ContactSide />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import pageWrapper from "@/components/Page-Wrapper.vue";
import ContactSide from "@/components/Treatment-Contact-Side.vue";
export default {
  data() {
    return {};
  },
  components: {
    pageWrapper,
    ContactSide,
  },
};
</script>

<style>
</style>