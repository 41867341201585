import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// initialize route pages
import Home from '../Views/Home-Page.vue'
import Contact from '../Views/Contact-Page.vue'
import AboutUs from '../Views/About-Us.vue'
import Clinic from '../Views/Our-Clinic.vue'
import Gallery from '../Views/Gallery-Page.vue'
import HealthTourism from '../Views/Health-Tourism.vue'
import Treatments from '../Views/Treatments-Page.vue'
import SmileDesign from '../Views/Treatments/Smile-Design.vue'
import TeethWhitening from '../Views/Treatments/Teeth-Whitening.vue'
import Implant from '../Views/Treatments/Implant-Page.vue'
import ZirconiumCrowns from '../Views/Treatments/Zirconium-Crowns.vue'
import ImpactedWisdomTeeth from '../Views/Treatments/Impacted-Wisdom-Teeth.vue'
import DentalDiseases from '../Views/Treatments/Dental-Diseases.vue'
import Orthodontics from '../Views/Treatments/Orthodontics-Page.vue'
import ChildrenDentistry from '../Views/Treatments/Children-Dentistry.vue'
import CariosityTreatment from '../Views/Treatments/Cariosity-Treatment.vue'
import PrivacyPolicy from '../Views/Contracts/Privacy-Policy.vue'
import TermsConditions from '../Views/Contracts/Terms-Conditions.vue'
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about-us',
        name: 'About Us',
        component: AboutUs
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/our-clinic',
        name: 'Clinic',
        component: Clinic
    },
    {
        path: '/gallery',
        name: 'Gallery',
        component: Gallery
    },
    {
        path: '/health-tourism',
        name: 'Dental For Health',
        component: HealthTourism
    },
    {
        path: '/treatments',
        name: 'Treatments',
        component: Treatments
    },
    {
        path: '/teeth-whitening',
        name: 'Teeth Whitening',
        component: TeethWhitening
    },
    {
        path: '/implant',
        name: 'Implant',
        component: Implant
    },
    {
        path: '/zirconium-crowns',
        name: 'Zirconium Crowns',
        component: ZirconiumCrowns
    },
    {
        path: '/impacted-wisdom-teeth',
        name: 'Impacted Wisdom Teeth',
        component: ImpactedWisdomTeeth
    },
    {
        path: '/dental-diseases',
        name: 'Dental Diseases',
        component: DentalDiseases
    },
    {
        path: '/orthodontics',
        name: 'Orthodontics',
        component: Orthodontics
    },
    {
        path: '/children-dentistry',
        name: 'Children Dentistry',
        component: ChildrenDentistry
    },
    {
        path: '/cariosity-treatment',
        name: 'Cariosity Treatment',
        component: CariosityTreatment
    },
    {
        path: '/privacy-Policy',
        name: 'Privacy Policy',
        component: PrivacyPolicy
    },
    {
        path: '/terms-conditions',
        name: 'Terms And Conditions',
        component: TermsConditions
    },
    {
        path: '/smile-design',
        name: 'Smile Design',
        component: SmileDesign
    }
]
const router = new VueRouter({
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        };
    },
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    document.title = `${to.name} - ${process.env.VUE_APP_TITLE}`;
    //document.title = `${process.env.VUE_APP_TITLE}`;
    next();
});

export default router