<template>
  <section class="gallery-page">
    <pageWrapper />
    <div class="gallery my-5">
      <VueGallery
        :images="images"
        :index="index"
        @close="index = null"
      ></VueGallery>
      <div
        class="image"
        v-for="(image, imageIndex) in images"
        :key="imageIndex"
        @click="index = imageIndex"
        :style="{
          backgroundImage: 'url(' + image + ')',
          width: '300px',
          height: '200px',
        }"
      ></div>
    </div>
  </section>
</template>
<script>
import VueGallery from "vue-gallery";
import pageWrapper from "../components/Page-Wrapper.vue";
export default {
  data: function () {
    return {
      images: [
        //require("../assets/gallery/dental-1.jpg"),
        require("../assets/gallery/dental-2.jpg"),
        require("../assets/gallery/dental-3.jpg"),
        require("../assets/gallery/dental-4.jpg"),
        require("../assets/gallery/dental-5.jpg"),
        require("../assets/gallery/dental-6.jpg"),
        require("../assets/gallery/dental-7.jpg"),
        require("../assets/gallery/dental-8.jpg"),
        require("../assets/gallery/dental-9.jpg"),
        require("../assets/gallery/dental-10.jpg"),
        require("../assets/gallery/dental-11.jpg"),
        require("../assets/gallery/dental-12.jpg"),
        require("../assets/gallery/dental-13.jpg"),
        require("../assets/gallery/dental-14.jpg"),
        require("../assets/gallery/dental-15.jpg"),
        require("../assets/gallery/dental-16.jpg"),
        require("../assets/gallery/dental-17.jpg"),
        require("../assets/gallery/dental-18.jpg"),
        require("../assets/gallery/dental-19.jpg"),
        require("../assets/gallery/dental-20.jpg"),
        require("../assets/gallery/dental-21.jpg"),
      ],
      index: null,
    };
  },

  components: {
    VueGallery,
    pageWrapper,
  },
};
</script> 