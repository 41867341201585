<template>
  <section class="health-page">
    <pageWrapper />
    <div class="container my-5">
      <div class="row">
        <div class="col-lg-8">
          <div class="col">
            <h1>{{ $t("TravelForHealth") }}</h1>
            <p>
              {{ $t("HealthTourismDesc") }}
            </p>
          </div>
          <div class="col">
            <h2>{{ $t("WorldClassDentalFacilities") }}</h2>
            <p>
              {{ $t("WorldClassDentalFacilitiesDesc") }}
            </p>
          </div>
          <div class="col">
            <h2>{{ $t("HighlyQualifiedDentalProfessionals") }}</h2>
            <p>
              {{ $t("HighlyQualifiedDentalProfessionalsDesc") }}
            </p>
          </div>
          <div class="col">
            <h2>{{ $t("AffordabilityAndQuality") }}</h2>
            <p>
              {{ $t("AffordabilityAndQualityDesc") }}
            </p>
          </div>
          <div class="col">
            <h2>{{ $t("BeautifulTouristDestination") }}</h2>
            <p>
              {{ $t("BeautifulTouristDestinationDesc") }}
            </p>
          </div>
          <div class="col">
            <h2>{{ $t("PersonalizedTreatmentPlansAndTimelyCare") }}</h2>
            <p>
              {{ $t("PersonalizedTreatmentPlansAndTimelyCareDesc") }}
            </p>
          </div>
          <div class="col">
            <router-link
              class="contact-btn"
              tag="a"
              to="/contact"
              title="Contact"
            >
              {{ $t("MakeAnAppointment") }}
              <img
                class="calendar"
                src="../assets/icons/calendar-w.svg"
                alt="calendar"
            /></router-link>
          </div>
        </div>
        <div class="col-lg-4">
          <figure>
            <img
              class="img-fluid"
              src="../assets/gallery/tourism.png"
              alt="tourism"
              loading="lazy"
            />
          </figure>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import pageWrapper from "../components/Page-Wrapper.vue";
export default {
  data() {
    return {};
  },
  components: {
    pageWrapper,
  },
};
</script>

<style>
</style>