<template>
  <section class="t-detail">
    <pageWrapper />
    <div class="container my-5">
      <div class="row">
        <div class="col-lg-8">
          <div class="col">
            <h1>{{ $t("SmileDesign") }}</h1>
            <p>
              {{ $t("SmileDesignDesc") }}
            </p>
            <p class="mt-2 mb-0">
              <strong>
                {{ $t("TeethWhitening") }}
              </strong>
            </p>
            <p>
              {{ $t("TeethWhiteningDesc") }}
            </p>
            <p class="mt-2 mb-0">
              <strong>
                {{ $t("DentalVeneers") }}
              </strong>
            </p>
            <p>
              {{ $t("DentalVeneersDesc") }}
            </p>
            <p class="mt-2 mb-0">
              <strong>
                {{ $t("DentalCrowns") }}
              </strong>
            </p>
            <p>
              {{ $t("DentalCrownsDesc") }}
            </p>
            <p class="mt-2 mb-0">
              <strong>
                {{ $t("OrthodonticTreatment") }}
              </strong>
            </p>
            <p>
              {{ $t("OrthodonticTreatmentDesc") }}
            </p>
            <p class="mt-2 mb-0">
              <strong>
                {{ $t("DentalImplants") }}
              </strong>
            </p>
            <p>
              {{ $t("DentalImplantsDesc") }}
            </p>
            <p class="mt-2 mb-0">
              <strong>
                {{ $t("DentalBonding") }}
              </strong>
            </p>
            <p>
              {{ $t("DentalBondingDesc") }}
            </p>
            <p class="mt-2 mb-0">
              <strong>
                {{ $t("GumContouring") }}
              </strong>
            </p>
            <p>
              {{ $t("GumContouring") }}
            </p>
            <p class="mt-2 mb-0">
              <strong>
                {{ $t("SmileMakeover") }}
              </strong>
            </p>
            <p>
              {{ $t("SmileMakeoverDesc") }}
            </p>
            <p>
              {{ $t("SmileDesignLastDesc") }}
            </p>
          </div>
          <div class="col">
            <router-link
              class="contact-btn"
              tag="a"
              to="/contact"
              title="Contact"
              >{{ $t("MakeAnAppointment") }}
              <img
                class="calendar"
                src="@/assets/icons/calendar-w.svg"
                alt="calendar"
                loading="lazy"
            /></router-link>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="container my-5 contact-side">
            <div class="row">
              <div class="col">
                <figure>
                  <img
                    class="img-thumbnail"
                    src="@/assets/treatments/Smile-Design.jpg"
                    alt="Smile Design"
                    loading="lazy"
                  />
                </figure>
                <div class="col">
                  <ContactSide />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import pageWrapper from "@/components/Page-Wrapper.vue";
import ContactSide from "@/components/Treatment-Contact-Side.vue";
export default {
  data() {
    return {};
  },
  components: {
    pageWrapper,
    ContactSide,
  },
};
</script>

<style>
</style>