<template>
  <section>
    <pageWrapper />
    <div class="container about-us">
      <h2>{{ $t("WhoWeAre") }}</h2>
      <p>
        {{ $t("AboutUsDesc1") }}
      </p>
      <p>
        {{ $t("AboutUsDesc2") }}
      </p>
    </div>
  </section>
</template>
<script>
import pageWrapper from "../components/Page-Wrapper.vue";
export default {
  data() {
    return {};
  },
  components: {
    pageWrapper,
  },
};
</script>

<style>
</style>